import { getAccessToken } from "./AccountService";
import config from "../config.json";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import Project from "../Models/Project";
import Phase from "../Models/Phase";
import TestType from "../Models/TestType";
import Question from "../Models/Question";
import Tests from "../Models/Tests";
import Assignment from "../Models/Assignment";
import Message from "../Models/Message";
import Quote from "../Models/Quote";
import FAQ from "../Models/FAQ";
import RecentActivitys from "../Models/RecentActivity";
import Notificationss from "../Models/Notification";
import UpdateProfilePictureResponse from "../Models/ProfilePictureresponse";



const getFetchOptions = async (
    instance: IPublicClientApplication,
    account: AccountInfo
): Promise<RequestInit> => {
    const accessToken = await getAccessToken(instance, account);

    let headers = new Headers();
    headers.append("Authorization", `Bearer ${accessToken}`);
    headers.append("Content-Type", "application/json");

    return {
        method: "GET",
        headers,
    };
};

//////Projects////



export async function getProjectById(
    id: number,
    instance: IPublicClientApplication,
    account: AccountInfo
): Promise<Project> {
    const options = await getFetchOptions(instance, account);

    let response = await fetch(
        `${config.ptaasApi.baseUrl}/Projects/${id}`,
        options
    );

    if (response.status === 204 || response.status === 200)
        return await response.json();

    throw new Error();
}
export async function getProjectByTestId(
    instance: IPublicClientApplication,
    account: AccountInfo,
    id: string
): Promise<Project> {
    const options = await getFetchOptions(instance, account);

    let response = await fetch(`${config.ptaasApi.baseUrl}/Projects/by-projectid/${id}`, options);

    if (response.status === 204 || response.status === 200)
        return await response.json();

    throw new Error();
}


export async function getPhaseById(
    projectId: number,
    phaseId: number,
    instance: IPublicClientApplication,
    account: AccountInfo
): Promise<Phase> {
    const options = await getFetchOptions(instance, account);

    const response = await fetch(
        `${config.ptaasApi.baseUrl}/projects/${projectId}/phases/${phaseId}`,
        options
    );

    if (response.status === 204 || response.status === 200)
        return await response.json();

    throw new Error();
}

export async function getPdfFromHtml(
    projectId: number,
    phaseId: number,
    instance: IPublicClientApplication,
    account: AccountInfo
): Promise<Blob> {
    const options = await getFetchOptions(instance, account);

    options.method = "GET";

    let url = `${config.ptaasApi.baseUrl}/documents/report-pdf?projectId=${projectId}`;

    if (phaseId !== null) url += `&phaseId=${phaseId}`;

    const response = await fetch(url, options);

    if (response.ok) return await response.blob();

    const errorMessage = await response.text();

    throw new Error(`${response.status}: ${errorMessage}`);
}


/* ---------------- */
/*     TestType     */
/* ---------------- */

export async function getTestType(
    instance: IPublicClientApplication,
    account: AccountInfo
): Promise<TestType[]> {
    const options = await getFetchOptions(instance, account);

    let response = await fetch(`${config.ptaasApi.baseUrl}/TestTypes`, options);

    if (response.status === 204 || response.status === 200)
        return await response.json();

    throw new Error();


}

/* ---------------- */
/* Scoping Question */
/* ---------------- */


export async function getQuestionsByQuestionId(
    instance: IPublicClientApplication,
    account: AccountInfo,
    id: string
): Promise<Question[]> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";
    let response = await fetch(`${config.ptaasApi.baseUrl}/ByQuestionId/${id}`, options);
    if (response.status === 204 || response.status === 200) return await response.json();

    throw new Error();
}




/* ------------------------*/
/* PreReq Question Section */
/* ------------------------ */


export async function getPrereqQuestionByQuestionId(

    instance: IPublicClientApplication,
    account: AccountInfo,
    id: string
): Promise<Question[]> {
    const options = await getFetchOptions(instance, account);

    let response = await fetch(`${config.ptaasApi.baseUrl}/PrereqQuestion/${id}`, options);

    if (response.status === 204 || response.status === 200) return await response.json();

    throw new Error();
}


/* -------------------------------------*/
/* Creating New Project Request Section */
/* ------------------------------------ */

export async function postnewproject
    (
        instance: IPublicClientApplication,
        account: AccountInfo,
        projectname: string,
        projectowner: string,
        owneremail: string,
        status: string,
        words2: string,
        assignment: Assignment[]
    ): Promise<boolean> {
    const options = await getFetchOptions(instance, account);
    options.method = "POST";
    const testtype = words2.split(',');
    options.body = JSON.stringify({
        projectname, projectowner, owneremail, status, testtype, assignment
    });
    let response = await fetch(`${config.ptaasApi.baseUrl}/Tests`, options);

    if (response.status === 204 || response.status === 200)
        return await response.ok;

    const errorMessage = await response.text();

    throw new Error(`${response.status}: ${errorMessage}`);
}

export async function getprojectforuser(
    instance: IPublicClientApplication,
    account: AccountInfo
): Promise<Tests[]> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";

    let response = await fetch(`${config.ptaasApi.baseUrl}/Tests`, options);


    if (response.status === 204 || response.status === 200) return await response.json();
    throw new Error();

}

export async function getprojectbyidforuser(
    instance: IPublicClientApplication,
    account: AccountInfo,
    id: string
): Promise<Tests> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";
    let response = await fetch(`${config.ptaasApi.baseUrl}/Tests/${id}`, options);
    if (response.status === 204 || response.status === 200) return await response.json();

    throw new Error();

}
export async function putprojectdetails(
    instance: IPublicClientApplication,
    account: AccountInfo,
    id: string,
    status: string,
    projectname: string,
    projectowner: string,
    owneremail: string,
    accepteddate: string,
    availabledate: Date[],
    comment: string,
    testType: string[],
    assignment: Assignment[]
)
    : Promise<Tests | null> {
    const options = await getFetchOptions(instance, account);
    options.method = "PUT";
    options.body = JSON.stringify(
        {
            "projectName": projectname,
            "projectOwner": projectowner,
            "owneremail": owneremail,
            "status": status,
            "testType": testType,
            "comment": comment,
            "assignment": assignment
        }
    );

    let response = await fetch(`${config.ptaasApi.baseUrl}/Tests/${id}`, options);
    if (!response.ok) {
        // Log or handle error more specifically here
        throw new Error(`API request failed with status ${response.status}: ${response.statusText}`);
    }

    // Since your API always returns OK, we check for the presence of content before parsing
    const text = await response.text(); // First get response as text
    if (!text) {
        // Handle case where no response body is present
        return null;
    }

    try {
        return JSON.parse(text); // Parse text as JSON
    } catch (error) {
        throw new Error('Failed to parse JSON response');
    }

}




/* -------------------------------------*/
/*         Quotes Section            */
/* ------------------------------------ */


export async function getquotebytestid
    (
        instance: IPublicClientApplication,
        account: AccountInfo,
        id: string

    ): Promise<Quote> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";
    let response = await fetch(`${config.ptaasApi.baseUrl}/Quote/${id}`, options);
    if (response.status === 204 || response.status === 200) return await response.json();

    throw new Error();
}

/* -------------------------------------*/
/*         FAQs Section            */
/* ------------------------------------ */
export async function getFAQs
    (
        instance: IPublicClientApplication,
        account: AccountInfo,
    ): Promise<FAQ[]> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";
    let response = await fetch(`${config.ptaasApi.baseUrl}/FAQs`, options);
    if (response.status === 204 || response.status === 200) return await response.json();

    throw new Error();

}


/* -------------------------------------*/
/*         Messaging Section            */
/* ------------------------------------ */
export async function getMessagesFromJira
    (
        instance: IPublicClientApplication,
        account: AccountInfo,
    ): Promise<Message[]> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";
    let response = await fetch(`${config.ptaasApi.baseUrl}/Messaging`, options);
    if (response.status === 204 || response.status === 200) return await response.json();

    throw new Error();

}
export async function GetChatByTestId(
    instance: IPublicClientApplication,
    account: AccountInfo,
    parentid: string,
): Promise<string> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";
    try {
        const response = await fetch(`${config.ptaasApi.baseUrl}/Messaging/${parentid}`, options);

        if (response.status === 200) {
            return await response.text();
        } else if (response.status === 204) {
            return await response.text(); //  An empty array of messages
        } else {
            // Handle other unexpected statuses
            throw new Error(`Failed to fetch Id: Server returned status ${response.status}`);
        }
    } catch (error) {
        // Log or handle errors more specifically if needed
        console.error('Error fetching Id from Jira:', error);
        throw new Error('There was an creating an chat window. Please try again later.');
    }
}

export async function PostMessages(
    instance: IPublicClientApplication,
    account: AccountInfo,
    id: number,
    text: string,
    sent: boolean,
    author: string

): Promise<boolean> {
    const options = await getFetchOptions(instance, account);
    options.method = "POST";
    options.body = JSON.stringify({ text, sent, author });

    try {
        const response = await fetch(`${config.ptaasApi.baseUrl}/Messaging/${id}`, options);

        if (response.status === 200) {
            return await response.json();
        } else if (response.status === 204) {
            return await response.json();; //  An empty array of messages
        } else {
            // Handle other unexpected statuses
            throw new Error(`Failed to fetch messages: Server returned status ${response.status}`);
        }
    } catch (error) {
        // Log or handle errors more specifically if needed
        console.error('Error fetching messages from Jira:', error);
        throw new Error('There was an error fetching messages from Jira. Please try again later.');
    }
}
/* -------------------------------------*/
/*      User Preference Section         */
/* ------------------------------------ */
export async function GetUserPreferenceDetails
    (
        instance: IPublicClientApplication,
        account: AccountInfo,

    ): Promise<User> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";

    let response = await fetch(`${config.ptaasApi.baseUrl}/UserProfile/UserDetails`, options);
    if (response.status === 204 || response.status === 200) return await response.json();

    throw new Error();
}
export async function GetProfilePicture
    (
        instance: IPublicClientApplication,
        account: AccountInfo,


    ): Promise<Blob> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";

    let response = await fetch(`${config.ptaasApi.baseUrl}/UserProfile`, options);
    if (response.status === 204 || response.status === 200) return await response.blob();

    throw new Error();
}

export async function UpdateProfilePicture(
    instance: IPublicClientApplication,
    account: AccountInfo,
    file: File
): Promise<UpdateProfilePictureResponse> {
    const formData = new FormData();
    formData.append('profilePic', file);
    const options = await getFetchOptions(instance, account);
    options.method = "POST";
    options.body = formData;

    if (options.headers) {
        if (options.headers instanceof Headers) {
            options.headers.delete('Content-Type'); // Using Headers API to delete
        } else {
            delete options.headers['Content-Type']; // For plain object headers
        }
    }

    try {
        let response = await fetch(`${config.ptaasApi.baseUrl}/UserProfile`, options);

        if (response.ok) {
            const blob = await response.blob(); // Get the blob from the response
            const newImageUrl = URL.createObjectURL(blob); // Create a URL for the Blob object
            return {
                success: true,
                message: 'Profile picture updated successfully.',
                newImageUrl: newImageUrl
            };
        } else {
            // Handle error responses by extracting the message from the response body
            const errorResponse = await response.json(); // Assume the error is in JSON format
            throw new Error(errorResponse.message || 'Failed to update the profile picture.');
        }
    } catch (error) {
        console.error('Error updating profile picture:', error);
        return {
            success: false,
            message: error.message || 'Unknown error occurred.'
        };
    }
}

export async function DeleteProfilePicture
    (
        instance: IPublicClientApplication,
        account: AccountInfo,


    ): Promise<UpdateProfilePictureResponse> {
    const options = await getFetchOptions(instance, account);
    options.method = "DELETE";
    try {
        let response = await fetch(`${config.ptaasApi.baseUrl}/UserProfile`, options);
        if (response.ok) {
            return {
                success: true,
                message: 'Profile picture updated successfully.'
            };
        } else {
            throw new Error('Failed to delete the profile picture.');
        }
    } catch (error) {
        console.error('Error deleting profile picture:', error);
        return {
            success: false,
            message: error.message || 'Unknown error occurred.'
        };
    }
}


/* -------------------------------------*/
/*      Notification   Section         */
/* ------------------------------------ */
export async function GetNotification
    (
        instance: IPublicClientApplication,
        account: AccountInfo,


    ): Promise<Notificationss[]> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";

    let response = await fetch(`${config.ptaasApi.baseUrl}/Notifications`, options);
    if (response.status === 204 || response.status === 200) return await response.json();

    throw new Error();
}

export async function UpdateNotification
    (
        instance: IPublicClientApplication,
        account: AccountInfo,
        id?: string
    ): Promise<boolean> {
    const options = await getFetchOptions(instance, account);
    options.method = "PUT";

    const url = id ? `${config.ptaasApi.baseUrl}/Notifications/${id}` : `${config.ptaasApi.baseUrl}/Notifications`;

    let response = await fetch(url, options);
    if (response.ok) {  // Checking if status is 200-299
        return response.json();  // Assuming the API returns a boolean in JSON format
    }

    throw new Error(`Failed to update Notification, status code: ${response.status}`);
}

/* -------------------------------------*/
/*      Recent Activity   Section         */
/* ------------------------------------ */
export async function GetRecentActivity
    (
        instance: IPublicClientApplication,
        account: AccountInfo,


    ): Promise<RecentActivitys[]> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";

    let response = await fetch(`${config.ptaasApi.baseUrl}/RecentActivity`, options);
    if (response.status === 204 || response.status === 200) return await response.json();

    throw new Error();
}

export async function UpdateRecentActivity
    (
        instance: IPublicClientApplication,
        account: AccountInfo,
        id?: string


    ): Promise<boolean> {
    const options = await getFetchOptions(instance, account);
    options.method = "PUT";

    const url = id ? `${config.ptaasApi.baseUrl}/RecentActivity/${id}` : `${config.ptaasApi.baseUrl}/RecentActivity`;

    let response = await fetch(url, options);
    if (response.ok) {  // Checking if status is 200-299
        return response.json();  // Assuming the API returns a boolean in JSON format
    }

    throw new Error(`Failed to update recent activity, status code: ${response.status}`);
}
/* -------------------------------------*/
/*      Sharepoint   Section         */
/* ------------------------------------ */

export async function UploadFiles
    (
        instance: IPublicClientApplication,
        account: AccountInfo,
        id: string,
        file: File,
        spid: string,
        isScoping: boolean


    ): Promise<FileEntryDetails> {
    const formData = new FormData();
    formData.append('file', file);

    const options = await getFetchOptions(instance, account);
    options.method = "POST";
    options.body = formData;
    if (options.headers) {
        if (options.headers instanceof Headers) {
            options.headers.delete('Content-Type'); // Using Headers API to delete
        } else {
            delete options.headers['Content-Type']; // For plain object headers
        }
    }
    let response = await fetch(`${config.ptaasApi.baseUrl}/FileUpload/${id}?spid=${spid}&isScoping=${isScoping}`, options);
    if (response.status === 204 || response.status === 200) { return response.json(); };

    throw new Error();
}

export async function getFilesByProjectTicketnumber
    (
        instance: IPublicClientApplication,
        account: AccountInfo,
        id: string
    ): Promise<FileEntryDetails[]> {
    const options = await getFetchOptions(instance, account);
    options.method = "GET";

    let response = await fetch(`${config.ptaasApi.baseUrl}/FileUpload/${id}`, options);
    if (response.status === 204 || response.status === 200) { return response.json(); };

    throw new Error();
}
export async function UploadFilesInChatFolder
    (
        instance: IPublicClientApplication,
        account: AccountInfo,
        id: number,
        file: File
    ): Promise<boolean> {
    const formData = new FormData();
    formData.append('file', file);

    const options = await getFetchOptions(instance, account);
    options.method = "POST";
    options.body = formData;
    if (options.headers) {
        if (options.headers instanceof Headers) {
            options.headers.delete('Content-Type'); // Using Headers API to delete
        } else {
            delete options.headers['Content-Type']; // For plain object headers
        }
    }
    let response = await fetch(`${config.ptaasApi.baseUrl}/FileUpload/Chats/${id}`, options);
    if (response.status === 204 || response.status === 200) { return response.ok; };

    throw new Error();
}


export async function Savefeedback(
    instance: IPublicClientApplication,
    account: AccountInfo,
    id: string,
    description: string,
    rating: number
): Promise<void> {
    // Ensure full description includes description and rating
    const fullDescription = `${description}\nRating is ${rating}`;

    // Generate fetch options
    const options = await getFetchOptions(instance, account);
    options.method = "PUT";
    options.body = JSON.stringify({ "Id": id, "Description": fullDescription });

    // Debugging: Log the body to verify if "Id" and "Description" are correctly passed
    console.log('Request Body:', options.body);

    // Make the API request
    let response = await fetch(`${config.ptaasApi.baseUrl}/Feedback`, options);

    // Check for errors in response
    if (!response.ok) {
        // Log or handle error more specifically here
        throw new Error(`API request failed with status ${response.status}: ${response.statusText}`);
    }
}
export async function uploadprereqinSharepoint(
    instance: IPublicClientApplication,
    account: AccountInfo,
    id: string,
    isScoping:boolean
): Promise<void> {
    const options = await getFetchOptions(instance, account);
    options.method = "POST";
   

    let response = await fetch(`${config.ptaasApi.baseUrl}/FileUpload/UploadDataInTextFile?id=${id}&isScoping=${isScoping}`, options);
    if (!response.ok) {
        // Log or handle error more specifically here
        throw new Error(`API request failed with status ${response.status}: ${response.statusText}`);
    }
}


