import React from "react";

const Footer = () => {
    return (
        <footer className="bg-light text-center py-3">
            <div className="footer-bottom">
                <p>© 2024 All Rights Reserved</p>
                <div className="footer-links">
                    <a href="#privacy-policy">Privacy Policy</a>
                    <span className="tooltip-container">
                        <a href="#terms-conditions" title="Use of this platform is subject to our Terms and Conditions available on request from your account manager">Terms and Conditions</a>
                    </span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
