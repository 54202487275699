import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { SuccessIcon } from '../Global/Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { putprojectdetails } from '../../Services/PtaasApiService';
import { Accordion, Row, Col, Table, Button, Modal, Form } from 'react-bootstrap';
import { FadeAccordion, FadeModal, FloatingField, LinkButton } from '../Global/Transitions';
import Tests from '../../Models/Tests';
import QuotePDF from './ProjectQuotePDF';
import TestType from '../../Models/TestType';
import lineItem from '../../Models/LineItems';
import Quote from '../../Models/Quote';

interface ProjectQuoteProps {
  test: Tests;
  lineItems: lineItem[];
  testTypes: TestType[];
  Quote: Quote;
  userinfo?: User;
}

const ProjectQuote: React.FC<ProjectQuoteProps> = ({ test, testTypes, lineItems, Quote }) => {
  const location = useLocation();
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const handleHideAccept = () => setShowAccept(false);
  const handleHideReject = () => setShowReject(false);
  const [calcTax, setCalcTax] = useState(0);
  const [calcGross, setCalcGross] = useState(0);
  const [calcTotal, setCalcTotal] = useState(0);
  const [comment, setComment] = useState('');
  const createdDate = new Date(+test._ts * 1000);
  const { accounts, instance } = useMsal();
  const navigate = useNavigate();

  // const [isChecked, setIsChecked] = useState(false);

  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };


  // Copy the createdDate to preserve the original created date
  const expireDate = new Date(createdDate.getTime());

  // Add 30 days to the expireDate
  expireDate.setDate(createdDate.getDate() + 30);

  // Format the created date
  const formattedCreatedDate = createdDate.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  // Format the expire date
  const formattedExpireDate = expireDate.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const handleShowAccept = async () => {
    await putprojectdetails(instance, accounts[0], test.id, "Quote Accepted", test.projectName, test.projectOwner, test.owneremail, test.accepteddate, test.availabledate, test.comment, test.testType, test.assignment);

    setShowAccept(true);
  };
  const handleShowReject = async () => {
    setShowReject(true);
  };
  const handleReject = async () => {
    await putprojectdetails(instance, accounts[0], test.id, "Quote Rejected", test.projectName, test.projectOwner, test.owneremail, test.accepteddate, test.availabledate, comment, test.testType, test.assignment);
    navigate('/dashboard');
  }

  useEffect(() => {
    // Calculate total amount
    const newTotalAmount = lineItems.reduce((total, item) => total + item.amount, 0);
    setCalcGross(parseFloat(newTotalAmount.toFixed(2)));

    // Calculate tax (20% of the total amount)
    const newTax = parseFloat((0.2 * newTotalAmount).toFixed(2));
    setCalcTax(newTax);

    // Calculate total amount with tax
    const newTotalAmountWithTax = parseFloat((newTotalAmount + newTax).toFixed(2));
    setCalcTotal(newTotalAmountWithTax);
  }, [lineItems]);

  return (
    <FadeAccordion defaultActiveKey='1' alwaysOpen>
      <Accordion.Item as='div' eventKey='1' className='position-relative overflow-hidden'>
        <Row className='no-after accordion-button bg-white border-0 m-0'>
          <Col sm={6} as='h3' className='p-0'>Quote (11/4)</Col>
          <Col sm={6} className='text-end p-0 mt-2'>
            <QuotePDF test={test} lineItems={lineItems} testTypes={testTypes} client={Quote?.client} />
          </Col>
        </Row>
        <Accordion.Body>
          <Row className='justify-content-end text-end pb-2'>
            <Col lg={5} className='me-n3'>
              <Table borderless size='sm' responsive className='m-0'>
                <tbody>
                  <tr>
                    <td className='text-gray'>Quote created</td>
                    <td>{formattedCreatedDate}</td>
                  </tr>
                  <tr>
                    <td className='text-gray'>Quote expires</td>
                    <td>{formattedExpireDate}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr />
          <Row className='pt-3'>
            <Col sm={9} className='d-flex flex-column pe-0'>
              <Table size='sm' borderless className='ms-n3 mt-n2'>
                <tbody>
                  <tr>
                    <td className='text-gray'>Item</td>
                    <td className='text-gray'>Descriptions</td>
                    <td className='text-gray text-end'>Line Amount</td>
                  </tr>
                  {lineItems?.map(lineItem =>
                    <tr key={lineItem.title}>
                      <td>{lineItem.title}</td>
                      <td>{lineItem.description}</td>
                      <td className='text-end prefix-sterling'>{lineItem.amount.toFixed(2)}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
            <Col sm={3} className='text-end border-start'>
              <p className='text-gray mt-n1 mb-0'>Amount (GBP)</p>
              <p className='display-5 text-success-emphasis prefix-sterling'>{calcTotal.toFixed(2)}</p>
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col sm={9} className='d-flex flex-column align-self-end pe-0'>
              <Table size='sm' borderless className='ms-n3 mb-n3'>
                <tbody>
                  <tr>
                    <td className='text-gray text-end'>Subtotal</td>
                    <td width={130} className='text-end prefix-sterling'>{calcGross.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td className='text-gray text-end'>Tax</td>
                    <td className='text-end prefix-sterling'>{calcTax.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td className='text-end h3'>Total</td>
                    <td className='text-end h4 prefix-sterling'>{calcTotal.toFixed(2)}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col sm={3} className='text-end border-start ps-3'>
              <p className='text-gray mb-0'>Payment Terms</p>
              <p>Invoice Due on Receipt</p>
              <p className='text-gray mb-0'>Reference</p>
              <p className='mb-n1'>d89cca4d-5b75-4583-9be0-lec648d0</p>
            </Col>
          </Row>

          {['Quote Available'].includes(test.status) ?
            <>
            {/* <div className="agreement-container">
             <input
                type="checkbox"
                id="agreement-checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <p className="agreement-text">
                By clicking here, you agree to our
                <a href="terms-and-conditions.html" className="underline"> Terms and Conditions</a> &
                <a href="privacy-policy.html" className="underline"> Privacy Policy</a>
              </p>
              </div> */}
              <hr className="py-2" />
              <Button variant="outline-primary" onClick={handleShowReject}>Reject</Button>
              <Button className="ms-3" onClick={handleShowAccept} >Accept</Button>
            </>
            : null}

          <FadeModal show={showReject} onHide={handleHideReject}>
            <Modal.Body className='text-center mt-5 mb-3 px-5'>
              <h1 className='m-0'>Reject Quote?</h1>
              <p>Please tell us why you want to reject this quote.</p>
              <Form>
                <FloatingField main negative label='Reason for Rejection' value={comment} onChange={(e) => setComment(e.target.value)} feedback='Please enter a reason' />
                <Button variant='outline-danger' onClick={handleHideReject}>Cancel</Button>
                <Button variant='danger' type='submit' onClick={handleReject} className='ms-3'>Reject Quote</Button>
              </Form>
            </Modal.Body>
          </FadeModal>
          <FadeModal show={showAccept} onHide={handleHideAccept}>
            <Modal.Body>
              <div className="text-center my-4">
                <SuccessIcon />
                <h1 className="m-0">Quote Accepted. Thank you!</h1>
                <p>Our team has been notified and will keep you posted on the progress.</p>
                <LinkButton to={location.pathname.replace('/quote', '/')}>Continue</LinkButton>
              </div>
            </Modal.Body>
          </FadeModal>
        </Accordion.Body>
      </Accordion.Item>
    </FadeAccordion>
  );
};

export default ProjectQuote;
