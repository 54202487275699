import '../charts.css';
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FadeAlert, LinkButton } from '../Components/Global/Transitions';
import {  GetChatByTestId, getProjectById, getprojectbyidforuser } from '../Services/PtaasApiService';
import { ChatIcon, LiveIcon, LoaderIcon } from '../Components/Global/Icons';
import Phase from '../Models/Phase';
import Project from '../Models/Project';
import ReportScore from '../Components/Reports/ReportScore';
import ReportPhase from '../Components/Reports/ReportPhase';
import RatingDistribution from '../Components/Reports/ReportDistribution';
import ReportSummaryChart from '../Components/Reports/ReportSummaryChart';
import Tests from '../Models/Tests';
import ReportContent from '../Models/ReportContent';
import ReportContents from '../Components/Reports/ReportContent';





const ReportSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const { id, canopyid } = useParams();
  const [name, setName] = useState('');
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(true);
  const [phases, setPhases] = useState<Phase[]>(null);
  const [reports, setReports] = useState<ReportContent[]>(null);
  const [fetchError, setFetchError] = useState(false);
  const [project, setProject] = useState<Project>(null);
  const [live,setLive]=useState(true);
  const totalFindings = { total: 0, critical: 0, high: 0, medium: 0, low: 0, info: 0, };
  const [chatid,setChatid]=useState('');
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);
      let returnedTest: Tests;
      let returnedProject: Project;
      
      try {
        returnedTest = await getprojectbyidforuser(instance, accounts[0], id);
        returnedProject = await getProjectById(parseInt(canopyid), instance, accounts[0]);
        console.log(returnedProject);
        setProject(returnedProject);
        setPhases(returnedProject?.phases);
        setReports(returnedProject?.reports);

        setLive(returnedProject.status==="Post Project"?false:true);
        setName(returnedTest?.projectName);
        const returnedid= await GetChatByTestId(instance, accounts[0],returnedTest.id);
        setChatid(returnedid);
      } catch {
        setFetchError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [navigate, canopyid, instance, accounts,id]);
  
  let testerRating: number = null;
  if (phases) {
    const ratings = phases.map((p) => (p?.tester_rating === "" ? 0 : parseInt(p.tester_rating)));
    if (ratings.length > 0) {
      const average = ratings.reduce((total, next) => total + next, 0) / ratings.length;
      testerRating = Math.floor(average);
    } else {
      testerRating = 0;
    }
  }

  phases?.forEach(phase => {
    if (phase.findings && Array.isArray(phase.findings)) {
      phase.findings.forEach(finding => {
        totalFindings.total++;
        totalFindings[['', 'critical', 'high', 'medium', 'low', 'info'][finding.rating]]++;
      });
    }
  });

  return (loading ? <LoaderIcon /> : (
    <Row>
      <Col lg={8} as='h1'>{name && name + ': Consolidated Report'}</Col>
      <Col lg={4} className='text-end'>
        <LinkButton to={`/messaging?id=${chatid}`}><ChatIcon />Messaging</LinkButton>
      </Col>
      <Col lg={8}>
        <FadeAlert show={fetchError} dismissFunction={() => setFetchError(false)} />
        <Alert show={live} variant='info' className='shadow mb-4'>
          <LiveIcon className='text-danger mt-n1' />
          These are live reports and are subject to change.
        </Alert>
      </Col>
      <Col lg={8}>
         <ReportSummaryChart phases={phases} project={project} />
         {reports?.map((report)=><ReportContents report={report} key ={report.id}/>)}
        {phases?.map((phase) => <ReportPhase phase={phase} isSummary key={phase.id} />)} 
      </Col>
      <Col lg={4}>
        <ReportScore score={testerRating} criticalCount={totalFindings.critical} />
        <RatingDistribution
          total={totalFindings.total}
          low={totalFindings.low}
          info={totalFindings.info}
          high={totalFindings.high}
          medium={totalFindings.medium}
          critical={totalFindings.critical}
        />
      </Col>
    </Row>
  ));
};


export default ReportSummaryPage;