import React from 'react';
import { FadeTable  } from '../Global/Transitions';
import ReportContent from '../../Models/ReportContent';






interface ReportContentProps {
    report: ReportContent;
}

const ReportContents: React.FC<ReportContentProps> = ({ report }) => {

    return (
        <div>
            <FadeTable className="nogradient" key={report.id}>
                <thead>
                    <tr>
                        <th colSpan={5}>
                            <h2 className={'d-inline-block me-2'}>{report.title}</h2>
                        </th>
                    </tr>
                </thead>
                <tbody className='text-left'>
                    <tr>
                        <td dangerouslySetInnerHTML={{ __html: report.content }} />
                    </tr>
                </tbody>
            </FadeTable>

        </div>
    );
};

export default ReportContents;